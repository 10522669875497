var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.createChampionshipModal),callback:function ($$v) {_vm.createChampionshipModal=$$v},expression:"createChampionshipModal"}},[_c('v-card',{staticClass:"py-5 pb-5"},[_c('v-overlay',{attrs:{"absolute":"","value":_vm.loadingTeams}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100","width":"5","color":"white"}})],1),_c('v-card-title',[_c('div',{staticClass:"text-center text-h5",staticStyle:{"width":"100%"}},[_vm._v(" Cadastrar campeonato ")])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"mx-5 mt-5",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.createChampionship()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":"Nome do campeonato *","outlined":"","required":""},model:{value:(_vm.championship.name),callback:function ($$v) {_vm.$set(_vm.championship, "name", $$v)},expression:"championship.name"}}),_c('v-menu',{ref:"startDatePicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Início do campeonato *","readonly":"","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.formattedStartsAt),callback:function ($$v) {_vm.formattedStartsAt=$$v},expression:"formattedStartsAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"pt-br"},model:{value:(_vm.championship.startsAt),callback:function ($$v) {_vm.$set(_vm.championship, "startsAt", $$v)},expression:"championship.startsAt"}})],1),_c('v-menu',{ref:"endDatePicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Término do campeonato *","readonly":"","outlined":"","rules":[_vm.rules.biggerThanStart]},model:{value:(_vm.formattedEndsAt),callback:function ($$v) {_vm.formattedEndsAt=$$v},expression:"formattedEndsAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"pt-br"},model:{value:(_vm.championship.endsAt),callback:function ($$v) {_vm.$set(_vm.championship, "endsAt", $$v)},expression:"championship.endsAt"}})],1),_c('v-autocomplete',{attrs:{"items":_vm.teams,"loading":_vm.loadingTeams,"search-input":_vm.searchTeam,"hide-no-data":"","hide-selected":"","multiple":"","outlined":"","solo":"","persistent-hint":"","return-object":"","item-text":"name","item-value":"_id","label":"Times participantes do campeonato","hint":"Você pode filtrar os resultados pelo nome do time. Caso ele não esteja nessa lista, faça uma busca avançada digitando o nome e apertando enter"},on:{"update:searchInput":function($event){_vm.searchTeam=$event},"update:search-input":function($event){_vm.searchTeam=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getTeams.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Nenhum time encontrado para esta pesquisa ")])],1)]},proxy:true},{key:"selection",fn:function(ref){return undefined}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"ma-3 team-image",attrs:{"tile":"","left":"","size":"50","color":"#F7F7F7"}},[(item.image)?_c('v-img',{attrs:{"src":item.image,"contain":"","max-height":"70%"},on:{"error":function($event){return _vm.teamImageErrorHandler(item._id)}}}):_c('span',{staticClass:"display-2 white--text text-center"},[_c('v-icon',[_vm._v(" mdi-soccer ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}]),model:{value:(_vm.championship.teams),callback:function ($$v) {_vm.$set(_vm.championship, "teams", $$v)},expression:"championship.teams"}}),_c('div',_vm._l((_vm.championship.teams),function(team){return _c('v-chip',{key:team._id,staticClass:"white--text mx-2",attrs:{"close":"","color":"primary"},on:{"click:close":function($event){return _vm.removeTeam(team._id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-soccer ")]),_c('span',{domProps:{"textContent":_vm._s(team.name)}})],1)}),1),_c('image-uploader',{attrs:{"title":"Clique aqui para enviar a logo do campeonato","subtitle":"Tamanho recomendado: 150 por 150 pixels","saving":_vm.saving,"fullWidth":true,"previewWidth":"150px","previewHeight":"150px"},on:{"img-uploaded":_vm.handleImage,"img-removed":_vm.handleRemoveImage}})],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"py-4",attrs:{"color":"primary","type":"submit","disabled":_vm.saving},on:{"click":function($event){return _vm.createChampionship()}}},[(_vm.saving)?_c('v-progress-circular',{staticClass:"px-6",attrs:{"indeterminate":"","size":"25","width":"3","color":"white"}}):_c('span',{staticClass:"px-6"},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }