<template>
  <v-dialog
    v-model="createChampionshipModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-overlay
        absolute
        :value="loadingTeams"
      >
        <v-progress-circular
          indeterminate
          size="100"
          width="5"
          color="white"
        />
      </v-overlay>

      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Cadastrar campeonato
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="mx-5 mt-5"
          lazy-validation
          @submit.prevent="createChampionship()"
        >
          <v-text-field
            v-model="championship.name"
            :rules="[rules.required]"
            label="Nome do campeonato *"
            outlined
            required
          />

          <v-menu
            ref="startDatePicker"
            v-model="startDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedStartsAt"
                label="Início do campeonato *"
                readonly
                outlined
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="championship.startsAt"
              no-title
              scrollable
              locale="pt-br"
            />
          </v-menu>

          <v-menu
            ref="endDatePicker"
            v-model="endDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndsAt"
                label="Término do campeonato *"
                readonly
                outlined
                :rules="[rules.biggerThanStart]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="championship.endsAt"
              no-title
              scrollable
              locale="pt-br"
            />
          </v-menu>

          <v-autocomplete
            v-model="championship.teams"
            :items="teams"
            :loading="loadingTeams"
            :search-input.sync="searchTeam"
            hide-no-data
            hide-selected
            multiple
            outlined
            solo
            persistent-hint
            return-object
            item-text="name"
            item-value="_id"
            label="Times participantes do campeonato"
            hint="Você pode filtrar os resultados pelo nome do time. Caso ele não esteja nessa lista, faça uma busca avançada digitando o nome e apertando enter"
            v-on:keyup.enter="getTeams"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Nenhum time encontrado para esta pesquisa
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ }"></template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                tile
                left
                size="50"
                color="#F7F7F7"
                class="ma-3 team-image"
              >
                <v-img
                  v-if="item.image"
                  :src="item.image"
                  contain
                  max-height="70%"
                  @error="teamImageErrorHandler(item._id)"
                />

                <span
                  v-else
                  class="display-2 white--text text-center"
                >
                  <v-icon>
                    mdi-soccer
                  </v-icon>
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <div>
            <v-chip
              v-for="team in championship.teams"
              :key="team._id"
              close
              color="primary"
              class="white--text mx-2"
              @click:close="removeTeam(team._id)"
            >
              <v-icon left>
                mdi-soccer
              </v-icon>
              <span v-text="team.name"></span>
            </v-chip>
          </div>
          <image-uploader
            title="Clique aqui para enviar a logo do campeonato"
            subtitle="Tamanho recomendado: 150 por 150 pixels"
            :saving="saving"
            :fullWidth="true"
            previewWidth="150px"
            previewHeight="150px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="createChampionship()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    ImageUploader: () => import('@/components/ImageUploader.vue')
  },
  data () {
    return {
      saving: false,
      valid: true,
      startDatePicker: false,
      endDatePicker: false,
      teams: [],
      loadingTeams: false,
      searchTeam: null,
      championship: {
        name: '',
        startsAt: '',
        endsAt: '',
        teams: [],
        imageType: undefined
      },
      image: null,
      rules: {
        required: value => !!value || 'Este campo é obrigatório.',
        biggerThanStart: value =>
          (!!value && moment(this.championship.endsAt).diff(moment(this.championship.startsAt), 'days') >= 1) || 'Informe uma data posterior à data de início.'
      }
    }
  },
  computed: {
    createChampionshipModal: {
      get () {
        return this.$store.state.createChampionshipModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createChampionshipModal',
          value: val
        })
      }
    },
    formattedStartsAt () {
      return this.championship.startsAt
        ? this.formatDate(this.championship.startsAt)
        : ''
    },
    formattedEndsAt () {
      return this.championship.endsAt
        ? this.formatDate(this.championship.endsAt)
        : ''
    }
  },
  watch: {
    createChampionshipModal (val) {
      if (!val) {
        this.championship = {
          name: '',
          startsAt: '',
          endsAt: '',
          teams: [],
          imageType: undefined
        }
      } else {
        this.getTeams()
      }
    }
  },
  methods: {
    getTeams () {
      this.loadingTeams = true
      var url = '/teams'
      if (this.searchTeam) {
        url = `${url}?search=${this.searchTeam}`
      } else {
        url = `${url}?page=1&pageSize=100`
      }

      this.$http.get(url)
        .then(res => {
          const { data } = res.data
          this.teams = data
        })
        .catch(err => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loadingTeams = false))
    },
    createChampionship () {
      if (this.$refs.form.validate()) {
        if (!this.championship.imageType) {
          this.$toast.error('É necessário enviar a logo do campeonato.')
          return
        }
        this.saving = true
        const teams = this.championship.teams.map(item => item._id)
        this.$http.post('/championships', {
          ...this.championship,
          teams
        })
          .then((res) => {
            const { data } = res
            if (data.putUrl) {
              this.uploadImage(data.putUrl, this.image, this.success, this.error)
            } else {
              this.success()
            }
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    success () {
      this.saving = false
      this.createChampionshipModal = false
      this.$emit('created')
      this.$toast.success('Cadastro realizado com sucesso.')
    },
    error () {
      this.saving = false
      this.createChampionshipModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.championship.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.championship.imageType = undefined
      this.image = null
    },
    teamImageErrorHandler (id) {
      const index = this.teams.findIndex(item => item._id === id)
      if (index >= 0) this.teams[index].image = imageOffIcon
    },
    removeTeam (id) {
      const teams = this.championship.teams.map(item => item._id)
      const index = teams.indexOf(id)
      if (index >= 0) this.championship.teams.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.team-image {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}
</style>
